export default {
    data() {
        return {
            logDataError: [],
            seeErrors: [],
            idMedicine: "",
            pagination: {},
            rowsPerPageItems: [5, 10, 15, 20],
            headers: [
              {
                sortable: false,
                text: "Número de fila",
                value: "plu",
                align: "left"
              },
              {
                sortable: false,
                text: "Descripción del error",
                align: "left"
              }
            ],
            itemsData: []
          };
    },
    methods: {
        updateError(data){
            this.$emit('updateError', data)
          },
        handleDataErrors(item) {
          },
          onRowClick () {
              
          }
    }
}